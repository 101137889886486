<template>
  <v-app>
    <HeaderComponent />
    <RedesSocialesComponent />
    <MainComponent />
    <FooterComponent />
  </v-app>
</template>

<script>
export default {
  components: {
    HeaderComponent: () => import("@/components/common/HeaderComponent"),
    RedesSocialesComponent : () => import("@/components/common/RedesSocialesComponent"),
    MainComponent : () => import("@/components/common/MainComponent"),
    FooterComponent: () => import("@/components/common/FooterComponent"),
  },
};
</script>
