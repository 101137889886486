const state = {
  listadoCategoria: [
    {
      id: "1",
      nombre: "news",
    },
    {
      id: "2",
      nombre: "material",
    },
    {
      id: "3",
      nombre: "tools",
    },
    {
      id: "4",
      nombre: "kitchen",
      foto: require("@/assets/utiles/KITCHEN.png"),
    },
    {
      id: "5",
      nombre: "bathroom",
      foto: require("@/assets/utiles/BATHROOM.png"),
    },
    {
      id: "6",
      nombre: "living room",
      foto: require("@/assets/utiles/LIVINGROOM.png"),
    },
    {
      id: "7",
      nombre: "backsplash",
    },
  ],
  listadoProducto: [
    {
      id: "1",
      producto: "CORTAG - TILE CUTTER MASTER 155",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/1 Cortag - Tile Cutter Master 155/1.jpg"),
        require("@/assets/utiles/products/Tools/1 Cortag - Tile Cutter Master 155/2.jpg"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "2",
      producto: "CORTAG - TILE CUTTER MASTER 125",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/2 Cortag - Tile Cutter Master 125/1.jpg"),
        require("@/assets/utiles/products/Tools/2 Cortag - Tile Cutter Master 125/2.jpg"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "3",
      producto: "CORTAG - TILE CUTTER PRACTIC 90",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/3 Cortag - Tile Cutter Practic 90/1.jpg"),
        require("@/assets/utiles/products/Tools/3 Cortag - Tile Cutter Practic 90/2.jpg"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "4",
      producto: "CORTAG - TILE CUTTER MASTER 90",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/4 Cortag - Tile Cutter Master 90/1.jpg"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "5",
      producto: "RUBI - TILE CUTTER TS 43 PLUS",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/5 Rubi - Tile Cutter Ts 43 Plus/1.png"),
        require("@/assets/utiles/products/Tools/5 Rubi - Tile Cutter Ts 43 Plus/2.jpg"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "6",
      producto: "RUBI - TILE CUTTER TS 66 PLUS",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/6 Rubi - Tile Cutter Ts 66 Plus/1.png"),
        require("@/assets/utiles/products/Tools/6 Rubi - Tile Cutter Ts 66 Plus/2.png"),
        require("@/assets/utiles/products/Tools/6 Rubi - Tile Cutter Ts 66 Plus/3.png"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "7",
      producto: "RUBI - TILE CUTTER TS 75 PLUS",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/7 Rubi - Tile Cutter Ts 75 Plus/1.png"),
        require("@/assets/utiles/products/Tools/7 Rubi - Tile Cutter Ts 75 Plus/2.png"),
        require("@/assets/utiles/products/Tools/7 Rubi - Tile Cutter Ts 75 Plus/3.png"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "8",
      producto: "RUBI - TILE CUTTER TX 1020",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/8 Rubi - Tile Cutter Tx 1020/1.png"),
        require("@/assets/utiles/products/Tools/8 Rubi - Tile Cutter Tx 1020/2.png"),
        require("@/assets/utiles/products/Tools/8 Rubi - Tile Cutter Tx 1020/3.png"),
        require("@/assets/utiles/products/Tools/8 Rubi - Tile Cutter Tx 1020/4.png"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "9",
      codigo_categoria: [3],
      producto: "RUBI - TILE CUTTER TX 710",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/9 Rubi - Tile Cutter Tx 710/1.png"),
        require("@/assets/utiles/products/Tools/9 Rubi - Tile Cutter Tx 710/2.png"),
        require("@/assets/utiles/products/Tools/9 Rubi - Tile Cutter Tx 710/3.png"),
        require("@/assets/utiles/products/Tools/9 Rubi - Tile Cutter Tx 710/4.png"),
      ],
      caracteristica: {},
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "10",
      producto: "DECOLINE BRIGHT",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/1 Decoline Bright/Decoline Bright.png"),
      ],
      caracteristica: {
        material: "ALUMINIUM",
        design: "BRIGHT",
        colour: "ALUMINIUM",
        size: "10FT",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "11",
      producto: "DECOLINE BRUSHED",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/2 Decoline Brushed/Decoline Brushed.png"),
      ],
      caracteristica: {
        material: "ALUMINIUM",
        design: "BRUSHED",
        colour: "ALUMINIUM",
        size: "10FT",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "12",
      producto: "GENESIS ESA 120.01",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/3 Genesis 120.01/Genesis 120.01.png"),
      ],
      caracteristica: {
        material: "METAL",
        design: "Smooth",
        colour: "ALUMINIUM",
        size: "8ft",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "13",
      producto: "GENESIS ESA 120.94",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/4 Genesis 120.94/Genesis 120.94.png"),
      ],
      caracteristica: {
        material: "METAL",
        design: "Brushed",
        colour: "ALUMINIUM",
        size: "8ft",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "14",
      producto: "PROFILITEC KJ100ATSB270",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/5 Profilitec KJ100ATS/Profilitec KJ100ATSN270.png"),
      ],
      caracteristica: {
        material: "ALUMINIUM",
        design: "SMOOTH",
        colour: "ALUMINIUM",
        size: "8ft",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "15",
      producto: "BACKER ON",
      descripcion: '1 1/14"',
      fotos: [
        require("@/assets/utiles/products/Material/6 Backer On 1 1slash14/1.png"),
        require("@/assets/utiles/products/Material/6 Backer On 1 1slash14/2.png"),
      ],
      caracteristica: {
        size: '1 1/14"',
        amount: "185 PIECES",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "16",
      producto: "ROCK-ON",
      descripcion: '1 5/8"',
      fotos: [
        require("@/assets/utiles/products/Material/7 Backer On 1 5slash8/1.png"),
        require("@/assets/utiles/products/Material/7 Backer On 1 5slash8/2.png"),
      ],
      caracteristica: {
        size: '1 5/8"',
        amount: "140 PIECES",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "17",
      producto: 'SPACER BUCKET 3/16"',
      descripcion: '',
      fotos: [
        require("@/assets/utiles/products/Material/8 Spacers Bucket 3slash16/1.png"),
      ],
      caracteristica: {
        material: "PLASTIC",
        amount: "400 PCS",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "18",
      producto: 'SPACER BUCKET 1/8"',
      descripcion: '',
      fotos: [
        require("@/assets/utiles/products/Material/9 Spacers Bucket 1slash8/1.png"),
      ],
      caracteristica: {
        material: "PLASTIC",
        amount: "1000 PCS",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "19",
      producto: "TILE LEVELING WEDGES BASE",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/10 Tile Leveling Wedges Base/TILE LEVELING.png"),
        require("@/assets/utiles/products/Material/10 Tile Leveling Wedges Base/LEVELING 01.png"),
      ],
      caracteristica: {
        material: "PLASTIC",
        size: "1/32",
        colour: "WHITE",
        amount: "100 PIECES",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "20",
      producto: "TILE LEVELING WEDGES",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/11 Tile Leveling Wedges Cunia/TILE LEVELING CUNIA.png"),
        require("@/assets/utiles/products/Material/11 Tile Leveling Wedges Cunia/CUNIA 01.png"),
      ],
      caracteristica: {
        material: "PLASTIC",
        size: "1/8, 1/2",
        colour: "RED",
        amount: "100 PIECES"
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "21",
      producto: "KNEE PADS PRO RUBI",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/12 COMPLETAR/1 Knee Pads Pro Rubi/1.jpg"),
        require("@/assets/utiles/products/Material/12 COMPLETAR/1 Knee Pads Pro Rubi/2.jpg"),
      ],
      caracteristica: {
        material: "PLASTIC",
        amount: "2 PCS",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "22",
      producto: "SPACERS RUBI",
      descripcion: '3/16"',
      fotos: [
        require("@/assets/utiles/products/Material/12 COMPLETAR/2 Spacers Rubi 3slash16/1.jpg"),
        require("@/assets/utiles/products/Material/12 COMPLETAR/2 Spacers Rubi 3slash16/2.jpg"),
      ],
      caracteristica: {
        material: "PLASTIC",
        size: '3/16"',
        amount: "100 PIECES",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "23",
      producto: "ARAL BEIGE",
      descripcion: "12X24",
      fotos: [
        require("@/assets/utiles/products/1 Kitchen/Aral Beige 12x24/1.png"),
        require("@/assets/utiles/products/1 Kitchen/Aral Beige 12x24/2.png"),
        require("@/assets/utiles/products/1 Kitchen/Aral Beige 12x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: true,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "24",
      producto: "ARAL GRAY",
      descripcion: "12X24 - 24X24",
      fotos: [
        require("@/assets/utiles/products/1 Kitchen/Aral Gray 12x24 y 24x24/1.png"),
        require("@/assets/utiles/products/1 Kitchen/Aral Gray 12x24 y 24x24/2.jpg"),
        require("@/assets/utiles/products/1 Kitchen/Aral Gray 12x24 y 24x24/3.jpg"),
        require("@/assets/utiles/products/1 Kitchen/Aral Gray 12x24 y 24x24/4.jpg"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24, 24X24",
        colour: "GRAY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: true,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "25",
      producto: "FOXTROT",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/1 Kitchen/FOXTROT 12x12/1.jpg"),
        require("@/assets/utiles/products/1 Kitchen/FOXTROT 12x12/2.png"),
        require("@/assets/utiles/products/1 Kitchen/FOXTROT 12x12/3.png"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: true,
        },
      ],
      referencia: false,
    },
    {
      id: "26",
      producto: "BRIDGE AQUA",
      descripcion: "24X48 - 24X24",
      fotos: [
        require("@/assets/utiles/products/2 Bathroom/Bridge Aqua 24x48 y 24x24/1.png"),
        require("@/assets/utiles/products/2 Bathroom/Bridge Aqua 24x48 y 24x24/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48, 24X24",
        colour: "GRAY BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: true,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "27",
      producto: "LOGAN GREY",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/2 Bathroom/Logan Grey 24x48/1.png"),
        require("@/assets/utiles/products/2 Bathroom/Logan Grey 24x48/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "WHITE GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: true,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "28",
      producto: "MOON LIGHT GREY",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/2 Bathroom/Moon Light Grey 24x48/1.png"),
        require("@/assets/utiles/products/2 Bathroom/Moon Light Grey 24x48/2.png"),
        require("@/assets/utiles/products/2 Bathroom/Moon Light Grey 24x48/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "LIGHT GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: true,
        },
      ],
      referencia: false,
    },
    {
      id: "29",
      producto: "COLORS WHITE",
      descripcion: "12X24 - 24X24",
      fotos: [
        require("@/assets/utiles/products/3 Living Room/Colors White 12x24 y 24x24/1.jpg"),
        require("@/assets/utiles/products/3 Living Room/Colors White 12x24 y 24x24/2.png"),
        require("@/assets/utiles/products/3 Living Room/Colors White 12x24 y 24x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24, 24X24",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: true,
        },
      ],
      referencia: false,
    },
    {
      id: "30",
      producto: "NERA GRIS",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/3 Living Room/Nera Gris 24x24/1.jpg"),
        require("@/assets/utiles/products/3 Living Room/Nera Gris 24x24/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: true,
        },
      ],
      referencia: false,
    },
    {
      id: "31",
      producto: "STONE WAVE DARK GRIP",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/3 Living Room/STONE WAVE DARK GRIP 24x24/1.jpg"),
        require("@/assets/utiles/products/3 Living Room/STONE WAVE DARK GRIP 24x24/2.jpg"),
        require("@/assets/utiles/products/3 Living Room/STONE WAVE DARK GRIP 24x24/3.jpg"),
        require("@/assets/utiles/products/3 Living Room/STONE WAVE DARK GRIP 24x24/4.jpg"),
        require("@/assets/utiles/products/3 Living Room/STONE WAVE DARK GRIP 24x24/5.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "STONE DARK",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: true,
        },
      ],
      referencia: false,
    },
    {
      id: "32",
      producto: "BERGAMO INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BERGAMO INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BERGAMO INTERLOCKING 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "33",
      producto: "BIMINI INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BIMINI INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BIMINI INTERLOCKING 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "34",
      producto: "BLOCKI GRIGIO",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BLOCKI GRIGIO 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BLOCKI GRIGIO 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/BLOCKI GRIGIO 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "35",
      producto: "CARRARA MATTE",
      descripcion: "2X2x12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CARRARA MATTE 2X2x12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CARRARA MATTE 2X2x12/2.jpg"),
      ],
      caracteristica: {
        size: "2X2X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "36",
      producto: "CHILCOTT SHIMMER",
      descripcion: "3X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CHILCOTT SHIMMER 3X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CHILCOTT SHIMMER 3X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CHILCOTT SHIMMER 3X12/3.jpg"),
      ],
      caracteristica: {
        size: "3X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "37",
      producto: "CITYSCAPE INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CITYSCAPE INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CITYSCAPE INTERLOCKING 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "38",
      producto: "CRISTALLO INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CRISTALLO INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CRISTALLO INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CRISTALLO INTERLOCKING 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/CRISTALLO INTERLOCKING 12X12/4.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "39",
      producto: "DELANO BLANCO",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DELANO BLANCO 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DELANO BLANCO 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DELANO BLANCO 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DELANO BLANCO 12X12/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DELANO BLANCO 12X12/5.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "40",
      producto: "DORADO PEBBLE TUMBLED",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DORADO PEBBLE TUMBLED 12.2X12.2/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DORADO PEBBLE TUMBLED 12.2X12.2/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/DORADO PEBBLE TUMBLED 12.2X12.2/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "41",
      producto: "HARLOW INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/HARLOW INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/HARLOW INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/HARLOW INTERLOCKING 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "42",
      producto: "ICE INTERLOCKING",
      descripcion: "12X18",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ICE INTERLOCKING 12X18/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ICE INTERLOCKING 12X18/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ICE INTERLOCKING 12X18/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ICE INTERLOCKING 12X18/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ICE INTERLOCKING 12X18/5.jpg"),
      ],
      caracteristica: {
        size: "12X18",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "43",
      producto: "IVORY AMBER BEVELED",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/IVORY AMBER BEVELED 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/IVORY AMBER BEVELED 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/IVORY AMBER BEVELED 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/IVORY AMBER BEVELED 12X12/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/IVORY AMBER BEVELED 12X12/5.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "44",
      producto: "KINGS GATE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/KINGS GATE 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/KINGS GATE 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "45",
      producto: "LAZIO BRICK",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/LAZIO BRICK 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/LAZIO BRICK 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "46",
      producto: "MAGICA PATTERN",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MAGICA PATTERN 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MAGICA PATTERN 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MAGICA PATTERN 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "47",
      producto: "METROPOLITAN PEBBLE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/METROPOLITAN PEBBLE 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/METROPOLITAN PEBBLE 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/METROPOLITAN PEBBLE 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "48",
      producto: "MIX RIVER PEBBLES",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MIX RIVER PEBBLES 12.2X12.2/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MIX RIVER PEBBLES 12.2X12.2/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/MIX RIVER PEBBLES 12.2X12.2/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "49",
      producto: "NIGHT SKY INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY INTERLOCKING 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY INTERLOCKING 12X12/4.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "50",
      producto: "NIGHT SKY SUBWAY",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY SUBWAY 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/NIGHT SKY SUBWAY 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "51",
      producto: "OASIS BLAST",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/OASIS BLAST 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/OASIS BLAST 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "52",
      producto: "PEBBLE STONE ALASKA GRAY",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE ALASKA GRAY 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE ALASKA GRAY 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "53",
      producto: "PEBBLE STONE ASH TUMBLED",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE ASH TUMBLED 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE ASH TUMBLED 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "54",
      producto: "PEBBLE STONE BLACK AND WHITE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/5.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/6.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE BLACK AND WHITE 12X12/7.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "55",
      producto: "PEBBLE STONE PUEBLA GREIGE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/Pebble Stone Puebla Greige 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/Pebble Stone Puebla Greige 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/Pebble Stone Puebla Greige 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/Pebble Stone Puebla Greige 12X12/4.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "56",
      producto: "PEBBLE STONE TRUFFLE TUMBLED",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE TRUFFLE TUMBLED 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/PEBBLE STONE TRUFFLE TUMBLED 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "57",
      producto: "POSITANO INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/POSITANO INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/POSITANO INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/POSITANO INTERLOCKING 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "58",
      producto: "ROYAL OAKS BLEND INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ROYAL OAKS BLEND INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ROYAL OAKS BLEND INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ROYAL OAKS BLEND INTERLOCKING 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/ROYAL OAKS BLEND INTERLOCKING 12X12/4.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "59",
      producto: "SANDHILLS INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SANDHILLS INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SANDHILLS INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SANDHILLS INTERLOCKING 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "60",
      producto: "SAVOY",
      descripcion: "2X6",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY 2X6/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY 2X6/2.jpg"),
      ],
      caracteristica: {
        size: "2X6",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "61",
      producto: "SAVOY INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY INTERLOCKING 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SAVOY INTERLOCKING 12X12/4.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "62",
      producto: "SILVER CANVAS",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/5.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/6.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/SILVER CANVAS 12X12/7.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "63",
      producto: "URBAN LOFT INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/URBAN LOFT INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/URBAN LOFT INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/URBAN LOFT INTERLOCKING 12X12/3.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "64",
      producto: "URBANKA INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/URBANKA INTERLOCKING 12X12/1.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "65",
      producto: "VOLCANIC LUXE INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/VOLCANIC LUXE INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/VOLCANIC LUXE INTERLOCKING 12X12/2.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "66",
      producto: "WHISTLER ICE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHISTLER ICE 12x12/1.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    {
      id: "67",
      producto: "WHITE WAVE INTERLOCKING",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHITE WAVE INTERLOCKING 12X12/1.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHITE WAVE INTERLOCKING 12X12/2.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHITE WAVE INTERLOCKING 12X12/3.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHITE WAVE INTERLOCKING 12X12/4.jpg"),
        require("@/assets/utiles/products/Backsplash Ms International/MS International/WHITE WAVE INTERLOCKING 12X12/5.jpg"),
      ],
      caracteristica: {
        size: "12X12",
      },
      ubicaciones: [
        {
          codigo_categoria: "7",
          descripcion: "BACKSPLASH",
          link: require("@/assets/icons/GRIS BACKSPLASH.png"),
          estado: false,
        },
      ],
      referencia: false,
    },
    /* ACA VIENE LOS REFERENCIA */
    {
      id: "68",
      producto: "AMALFI BRILLO",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Amalfi Brillo 24x24/1.jpg"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "69",
      producto: "BOIS BLANCO",
      descripcion: "10X38",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Bois Blanco 10x38/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "10X38",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "70",
      producto: "BOIS GRIS",
      descripcion: "10X38",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Bois Blanco 10x38/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "10X38",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "72",
      producto: "CARRARA BLANCO BRILLO",
      descripcion: "8X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Carrara Blanco Brillo 8x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X24",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "73",
      producto: "LLANES MARFIL BRILLO",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Llanes Marfil Brillo 24x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "74",
      producto: "MEDICI GRIS MATTE",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Medici Gris Matte 24x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "75",
      producto: "PRESTIGE LIGHT",
      descripcion: "12X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Prestige Light 12x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "76",
      producto: "PRESTIGE SILVER",
      descripcion: "12X24",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Prestige Silver 12x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24",
        colour: "SILVER",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "77",
      producto: "SEQUOIA ICE",
      descripcion: "9X48",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Sequoia ice 9x48/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "9X48",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "78",
      producto: "SEQUOIA OLD WHITE",
      descripcion: "9X48",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Sequoia Old White 9x48/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "9X48",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "79",
      producto: "TROPICAL GREY",
      descripcion: "9X48",
      fotos: [
        require("@/assets/utiles/products/Palo Rosa/Tropical Grey 9x48/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "9X48",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "80",
      producto: "AGATA PEARL",
      descripcion: "24X24 - 12X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Agata Pearl 24x24 y 12x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24, 12X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "81",
      producto: "COLORS GREY",
      descripcion: "24X48, 32X32",
      fotos: [
        require("@/assets/utiles/products/Todagres/Colors Grey 24x48 y 32x32/1.png"),
        require("@/assets/utiles/products/Todagres/Colors Grey 24x48 y 32x32/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48, 32X32",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "82",
      producto: "COLORS PEARL",
      descripcion: "32X32",
      fotos: [
        require("@/assets/utiles/products/Todagres/Colors Pearl 32x32/1.png"),
        require("@/assets/utiles/products/Todagres/Colors Pearl 32x32/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "32X32",
        colour: "PEARL",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "83",
      producto: "LOGAN GOLD",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/Todagres/Logan Gold 24x48/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "WHITE GOLD",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "84",
      producto: "NATURAL FOREST",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/Todagres/Natural Forest 24x48/1.png"),
        require("@/assets/utiles/products/Todagres/Natural Forest 24x48/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "85",
      producto: "NERA ANTRACITA",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Nera Antracita 24x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "GREY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },

    {
      id: "86",
      producto: "NERA BLANCO",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Nera Blanco 24x24/1.png"),
        require("@/assets/utiles/products/Todagres/Nera Blanco 24x24/2.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "87",
      producto: "ONIX BEIGE",
      descripcion: "12X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Onix Beige 12x24/1.png"),
        require("@/assets/utiles/products/Todagres/Onix Beige 12x24/2.png"),
        require("@/assets/utiles/products/Todagres/Onix Beige 12x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "88",
      producto: "ONIX PEARL",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Onix Pearl 24x24/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "PEARL",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "89",
      producto: "POLAR BLANCO",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Todagres/Polar Blanco 24x24/1.png"),
        require("@/assets/utiles/products/Todagres/Polar Blanco 24x24/2.png"),
        require("@/assets/utiles/products/Todagres/Polar Blanco 24x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },  
    {
      id: "90",
      producto: "APPIA MURETTO",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/APPIA MURETTO 12X12/1.jpg"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "91",
      producto: "BOOGIE",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/Boogie 12x12/1.png"),
        require("@/assets/utiles/products/Unicom Starker/Boogie 12x12/2.jpg"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "BROWN",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "92",
      producto: "CABANE FOG RECTIFIED",
      descripcion: "8X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CABANE FOG RET. 8X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CABANE FOG RET. 8X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE FOG RET. 8X48/3.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE FOG RET. 8X48/4.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE FOG RET. 8X48/5.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X48",
        colour: "FOG",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "93",
      producto: "CABANE SAND RECTIFIED",
      descripcion: "8X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CABANE SAND RET. 8X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SAND RET. 8X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SAND RET. 8X48/3.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SAND RET. 8X48/4.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X48",
        colour: "SAND",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },

    {
      id: "94",
      producto: "CABANE SHELL RECTIFIED",
      descripcion: "8X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CABANE SHELL RET. 8X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SHELL RET. 8X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SHELL RET. 8X48/3.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE SHELL RET. 8X48/4.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X48",
        colour: "SHELL",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "95",
      producto: "CABANE STONE RECTIFIED",
      descripcion: "8X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CABANE STONE RET. 8X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CABANE STONE RET. 8X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE STONE RET. 8X48/3.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE STONE RET. 8X48/4.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X48",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "96",
      producto: "CABANE TOBACCO",
      descripcion: "8X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CABANE TOBACCO 8X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CABANE TOBACCO 8X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE TOBACCO 8X48/3.png"),
        require("@/assets/utiles/products/Unicom Starker/CABANE TOBACCO 8X48/4.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "8X48",
        colour: "TOBACCO",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "97",
      producto: "CLASSIC",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/CLASSIC 12x12/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/CLASSIC 12x12/2.png"),
        require("@/assets/utiles/products/Unicom Starker/CLASSIC 12x12/3.png"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "98",
      producto: "MAXXI TWO",
      descripcion: "18X36",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/MAXXI TWO 18X36/1.png"),
        require("@/assets/utiles/products/Unicom Starker/MAXXI TWO 18X36/2.png"),
        require("@/assets/utiles/products/Unicom Starker/MAXXI TWO 18X36/3.jpg"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "18X36",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "99",
      producto: "OLIVE MURETTO",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/OLIVE MURETTO 12X12/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/OLIVE MURETTO 12X12/2.jpg"),
        require("@/assets/utiles/products/Unicom Starker/OLIVE MURETTO 12X12/3.png"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "BROWN",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "100",
      producto: "PAPYRUS POLISH RECTIFIED",
      descripcion: "18X36",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/PAPYRUS POLISH. RETT 18x36/1.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "18X36",
        colour: "GRAY",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "101",
      producto: "PEARL MURETTO SATIN",
      descripcion: "12X12",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/PEARL MURETTO SATIN 12X12/1.jpg"),
      ],
      caracteristica: {
        material: "MOSAIC",
        size: "12X12",
        colour: "PEARL",
      },
      ubicaciones: [
        {
          codigo_categoria: "4",
          descripcion: "KITCHEN",
          link: require("@/assets/icons/GRIS KITCHEN.png"),
          estado: false,
        },
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "102",
      producto: "PEARL POLISHED RECTIFIED",
      descripcion: "18X36",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/PEARL POLISHED RETT 18X36/1.png"),
        require("@/assets/utiles/products/Unicom Starker/PEARL POLISHED RETT 18X36/2.jpg"),
        require("@/assets/utiles/products/Unicom Starker/PEARL POLISHED RETT 18X36/3.jpg"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "18X36",
        colour: "PEARL",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "103",
      producto: "PIETRA DI GRE BIANCO",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE BIANCO 24X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE BIANCO 24X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE BIANCO 24X48/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "STONE WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "104",
      producto: "PIETRA DI GRE STR",
      descripcion: "24X48",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE GRIGIO STR. 24X48/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE GRIGIO STR. 24X48/2.png"),
        require("@/assets/utiles/products/Unicom Starker/PIETRA DI GRE GRIGIO STR. 24X48/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X48",
        colour: "STONE WHITE",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "105",
      producto: "POLESTAR SPACE",
      descripcion: "12X24 - 24X24",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/POLESTAR SPACE  12X24 y 24x24/1.png"),
        require("@/assets/utiles/products/Unicom Starker/POLESTAR SPACE  12X24 y 24x24/2.jpg"),
        require("@/assets/utiles/products/Unicom Starker/POLESTAR SPACE  12X24 y 24x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24, 24X24",
        colour: "BROWN",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "106",
      producto: "POLESTAR THUNDER GRIP",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/POLESTAR THUNDER GRIP 24X24/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/POLESTAR THUNDER GRIP 24X24/2.png"),
        require("@/assets/utiles/products/Unicom Starker/POLESTAR THUNDER GRIP 24X24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "STONE BLACK",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "107",
      producto: "RAW JUTA NAT",
      descripcion: "12X24",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/RAW JUTA NAT. 12X24/1.png"),
        require("@/assets/utiles/products/Unicom Starker/RAW JUTA NAT. 12X24/2.png"),
        require("@/assets/utiles/products/Unicom Starker/RAW JUTA NAT. 12X24/3.png"),
        require("@/assets/utiles/products/Unicom Starker/RAW JUTA NAT. 12X24/4.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "12X24",
        colour: "STONE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "108",
      producto: "RENAI IVORY GRIP",
      descripcion: "18X36",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/RENAI IVORY GRIP 18X36/1.png"),
        require("@/assets/utiles/products/Unicom Starker/RENAI IVORY GRIP 18X36/2.jpg"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "18X36",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "5",
          descripcion: "BATHROOM",
          link: require("@/assets/icons/GRIS BATHROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "109",
      producto: "STONE WAVE LIGHT GRIP",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE LIGHT GRIP 24x24/1.jpg"),
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE LIGHT GRIP 24x24/2.png"),
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE LIGHT GRIP 24x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "STONE DARK",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "110",
      producto: "STONE WAVE WARM GRIP",
      descripcion: "24X24",
      fotos: [
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE WARM GRIP 24x24/1.png"),
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE WARM GRIP 24x24/2.jpg"),
        require("@/assets/utiles/products/Unicom Starker/STONE WAVE WARM GRIP 24x24/3.png"),
      ],
      caracteristica: {
        material: "PORCELAIN",
        size: "24X24",
        colour: "BEIGE",
      },
      ubicaciones: [
        {
          codigo_categoria: "6",
          descripcion: "LIVINGROOM",
          link: require("@/assets/icons/GRIS LIVING ROOM.png"),
          estado: false,
        },
      ],
      referencia: true,
    },

    {
      id: "111",
      producto: "DEWALT D24000S",
      descripcion: "",
      fotos: [require("@/assets/utiles/products/Tools/D24000s/1.jpg")],
      caracteristica: {},
      specifications: "- PREMIUM TILE WET SAW WITH STAND",
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "112",
      producto: "DRY GROUT - JAMO",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/Dry Grout/1.jpeg"),
        require("@/assets/utiles/products/Material/Dry Grout/JAMO CARD.png"),
      ],
      caracteristica: {
        weight: "25lb",
        "product type": "UNSANDED",
      },
      specifications:
        "- Polymer-modified - mix with water \n- Resistant to shrinking and cracking \n- Fast setting and smooth texture",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "113",
      producto: "GTS - JAMO",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/JAMO - GTS THINSET/1.jpeg"),
      ],
      caracteristica: {
        weight: "50lb",
      },
      specifications:
        "- Use as a bond coat for setting tile \n- Excellent water and impact resistant dry set mortar \n- Requires no soaking of tiles \n- Covers up to 100 sq. ft",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "114",
      producto: "MULTI-BOND - JAMO",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/JAMO - LFT MULTI - BOND/1.jpeg"),
      ],
      caracteristica: {
        weight: "50lb",
      },
      specifications:
        "- Polymer modified \n- For interior and exterior floors and walls \n- Covers approximately 80 - 100 square feet per 50 lb. bag \n- Can be used in residential and commercial installations and requires no soaking of tiles",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "115",
      producto: "MULTI FLOOR-SET- Q FLEX - JAMO",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/JAMO - Q FLEX/1.jpeg"),
      ],
      caracteristica: {
        weight: "50lb",
      },
      specifications:
        "- Use as a bond coat for setting tile \n- Excellent water and impact resistant dry set mortar \n- Requires no soaking of tiles \n- Covers up to 100 sq. ft",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "116",
      producto: "MASTER COLOR GROUT - JAMO",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/Master Color/1.jpeg"),
        require("@/assets/utiles/products/Material/Master Color/JAMO CARD.png"),
      ],
      caracteristica: {
        weight: "25lb",
        "product type": "Sanded",
      },
      specifications:
        "- Polymer-modified - mix with water \n- Resistant to shrinking and cracking \n- Fast setting and smooth texture",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "117",
      producto: "QUIKRETE - FLOOR MUD",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/Quikrete - Floor Mud/1.jpeg"),
      ],
      caracteristica: {
        weight: "60lb",
        "product type": "Sanded",
      },
      specifications:
        "- Ideal for pouring concrete slabs and setting posts \n- 4000 psi average compressive concrete strength \n- 60 minute working time \n- Just add water",
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "118",
      producto: "CUSTOM PRISM GROUT",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Material/Custom Prism/1.jpeg"),
        require("@/assets/utiles/products/Material/Custom Prism/Paleta Custom Prism.png")
      ],
      caracteristica: {
        weight: "17lb",
      },
      ubicaciones: [
        {
          codigo_categoria: "2",
          descripcion: "",
          link: require("@/assets/icons/GRIS MATERIAL.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
    {
      id: "119",
      producto: "DEWALT D36000S",
      descripcion: "",
      fotos: [
        require("@/assets/utiles/products/Tools/D36000s/1.jpg"),
        require("@/assets/utiles/products/Tools/D36000s/2.jpg"),
        require("@/assets/utiles/products/Tools/D36000s/3.jpg"),
      ],
      caracteristica: {},
      specifications: "- 10IN HIGH CAPACITY \n- WET TILE SAW WITH STAND",
      ubicaciones: [
        {
          codigo_categoria: "3",
          descripcion: "",
          link: require("@/assets/icons/GRIS TOOLS.png"),
          estado: false,
        },
      ],
      referencia: true,
    },
  ],
  banner: [
    require("@/assets/utiles/banner/BANNER 01.jpg"),
    require("@/assets/utiles/banner/BANNER 02.jpg"),
    require("@/assets/utiles/banner/BANNER 03.jpg"),
    require("@/assets/utiles/banner/BANNER 04.jpg"),
  ],

  icons: [
    
    
    

    {
      imagen: require("@/assets/icons/white/BACKSPLASH.png"),
      ruta: "7",
    },
    {
      imagen: require("@/assets/icons/white/BATHROOM.png"),
      ruta: "5",
    },
    {
      imagen: require("@/assets/icons/white/KITCHEN.png"),
      ruta: "4",
    },
    {
      imagen: require("@/assets/icons/white/LIVING ROOM.png"),
      ruta: "6",
    },
  ],

  actualizar: "",
};

export default state;
