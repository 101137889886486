import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView"),
  },
  {
    path: "/responsability",
    name: "responsability",
    component: () => import("@/views/ResponsabilityView"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactView.vue"),
  },
  {
    path: "/point-sales",
    name: "point-sales",
    component: () => import("@/views/PointsView.vue"),
  },
  {
    path: "/products/:id",
    name: "products",
    component: () => import("@/views/ListProductsView.vue"),
  },
  {
    path: "/view-product/:id",
    name: "view-product",
    component: () => import("@/views/ProductView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      window.scrollTo("0", "0");
    }
  },
});

export default router;
