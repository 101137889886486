import axios from "axios";

const actions = {
  async __enviarCorreo({ commit }, datos) {
    let respuesta = null;
    console.log(commit);
    var formData = new FormData();
    formData.append("name", datos.name);
    formData.append("email", datos.email);
    formData.append("phone", datos.phone);
    formData.append("subject", datos.subject);
    formData.append("comment", datos.comment);
    /* https://formspree.io/f/xeqnkvkn CLIENTE ---> */
    /* https://formspree.io/f/mleznaal MIO ---> */
    await axios
      .post("https://formspree.io/f/xeqnkvkn", formData)
      .then( ( { data } ) => {  
        const { ok } = data; 
        respuesta = ok;
      })
      .catch((error) => {
        console.log(error);
        respuesta = false;
      });
    return respuesta;
  },
};

export default actions;
